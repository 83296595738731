<template>
    <div class="output gray-bg" v-if="articledata && articledata.attributes">
        <div class="col1">
            <div v-if="!articledata.field_image" class="riga-immagine">
                <img class="placeholder" src="../../assets/output-image.svg" alt="project output cover">
            </div>

            <ImageItem v-else :imagedata="articledata" 
                fieldname="field_image" 
                :breakstyles="{'0': 'sm_image','768': 'md_image'}"
            />
        </div>

        <div class="col2">
            <h4 v-if="articledata.attributes.title">{{ articledata.attributes.title }}</h4>

            <p v-if="articledata.attributes.field_text_with_summary"
                v-html="articledata.attributes.field_text_with_summary.value"></p>

            <br>
            <br>

            <a  v-if="articledata.attributes.field_url"
                class="output-link" target="_blank" :href="articledata.attributes.field_url.uri">{{ 
                articledata.attributes.field_url.title ? articledata.attributes.field_url.title : articledata.attributes.field_url.uri }}
            </a>

            <a v-for="(att, i) in articledata.field_attachments" :key="'att'+i"
                class="btn download-link slim bold plain" :href="endpoint + att.attributes.uri.url" download
                target="_blank">
                <span class="download icon-right" v-if="articledata.relationships.field_attachments.data[i].meta.description != '' ">
                    {{articledata.relationships.field_attachments.data[i].meta.description}}
                </span>

                <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
            </a>
        </div>

    </div>

</template>

<script>
import ImageItem from '../../components/atomic-elements/ImageItem.vue'
export default {
    name: 'output-card',
    components: {
        ImageItem
    },
    props: {
        articledata: Object
    },
    computed: {
        endpoint() {
            return process.env.VUE_APP_ENDPOINT
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.output {
    padding: 0 .5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .col1 {
        width: 100%;
        height: 200px;
        .riga-immagine, picture {
            width: 100%;
            height: 200px;
            object-fit: contain;
        }

        .riga-immagine {
            position: relative;
            &:after {
                content: '';
                width: 100%;
                height: .5rem;
                left: 0;
                top: calc(200px - .5rem);
                position: absolute;
                z-index: 10;
                background: $gradient;
            }
        }

        .placeholder {
            background: #fff;
            padding: 2em;
            width: 100%;
            height: 200px;
            object-fit: contain;
        }


    }

    .col2 {
        width: 100%;
        p, ::v-deep p {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.download-link {
    background: $lightGray;
}

.output-link {
    color: $middleBlue;
    text-decoration: none;
    -webkit-text-fill-color: unset;
    background: unset;
    background-clip: unset;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (min-width: 768px) {
    .output {
        padding: 0 2em 2em 0;
        flex-direction: row;

        .col1 {
            width: 300px;
            height: 300px;
            .riga-immagine, picture {
                width: 100%;
                height: 100%;
            }

            .riga-immagine:after {
                width: 100%;
                top: calc(300px - .5rem);
            }

            .placeholder {
                padding: 33%;
                width: 100%;
                height: 100%;
            }
        }
        .col2 {
            width: calc(95% - 300px);
        }
    }
}


</style>