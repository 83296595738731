<template>
  <div class="page-layout wave-filigrana detail-page details project-detail">

    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData && !newsData">
      <div class="step">
        <br>
        <h3>Loading Data ...</h3>
        <br>
      </div>
    </div>

    <div v-if="articleData" class="container">

      <div class="step">

        <p v-if="articleData.field_ref_working_group"
          :class="['detail--tag', 'blue', 'icon-left', 'target'+articleData.field_ref_working_group.attributes.drupal_internal__nid]">
          {{ articleData.field_ref_working_group.attributes.title }}
        </p>

        <br>

        <h1>{{ articleData.attributes.title + ' [' + articleData.attributes.field_acronym + ']'}}</h1>

        <div class="content">
          <p class="label label-title">Project Phases</p>
          <div class="phases">
            <div v-for="(f, index) in this.phaseData" :key="'pahse'+index" :class="['phase', 
                index>articleData.attributes.field_phase ? 'grayed' : '',
                index==articleData.attributes.field_phase ? 'big' : '']">
              <div class="number">{{ index }}</div>
              <div class="phase-name">{{ f }}</div>
            </div>
          </div>

          <p class="description" v-if="articleData.attributes.field_description"
            v-html="articleData.attributes.field_description.value">
          </p>

          <p v-if="articleData.attributes.field_resume" class="label label-title">Resume</p>
          <p v-if="articleData.attributes.field_resume" v-html="articleData.attributes.field_resume.value"></p>

          <p v-if="articleData.attributes.field_synergetic_actions" class="label label-title">Synergetic Actions</p>
          <p v-if="articleData.attributes.field_synergetic_actions"
            v-html="articleData.attributes.field_synergetic_actions.value"></p>
        </div>

        <div class="attachments-grid">
          <div class="box-row">


            
          </div>

          <div class="box-row">
            <div class="box">
              <p v-if="articleData.field_report" class="label">REPORTS</p>
              <a v-if="articleData.field_report" class="btn download-link slim bold plain"
                :href="endpoint + articleData.field_report.attributes.uri.url" target="_blank">
                <span class="download icon-right">{{ articleData.field_report.attributes.filename }}</span>
              </a>
            </div>
            <div class="box">
              <p v-if="articleData.field_attachments.length" class="label">OTHER ATTACHMENTS</p>
              <a v-for="(att, i) in articleData.field_attachments" :key="'att'+i"
                class="btn download-link slim bold plain" :href="endpoint + att.attributes.uri.url" download
                target="_blank">
                <span class="download icon-right" v-if="articleData.relationships.field_attachments.data[i].meta.description != '' ">
                  {{articleData.relationships.field_attachments.data[i].meta.description}}
                </span>

                <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
              </a>
            </div>
          </div>


        </div>
      </div>

      <aside class="coordinators">
        <div class="people-row">
          <div class="box" v-if="articleData.attributes.field_pilot_project_email">
            <p class="label">CONTACT EMAIL</p>
            <a :href="'mailto:'+articleData.attributes.field_pilot_project_email" class="email dark"
              style="margin: 1em 0;">
              <span class="icon-left email">{{ articleData.attributes.field_pilot_project_email }}</span>
            </a>
          </div>
        </div>

        <div class="box links">
            <a v-if="articleData.attributes.field_url" :href="articleData.attributes.field_url.uri" target="_blank"
              class="btn plain slim border">
              <span class="icon-right external">Go to website</span>
            </a>
        </div>
        <ImageItem class="logo" v-if="articleData.field_logo" :imagedata="articleData" fieldname="field_logo"
          :breakstyles="{'0': 'sm_image','576': 'md_image' , '1024': 'lg_image'}">
        </ImageItem>

        <div class="people-row" v-if="contactPersonsLength">
          <p class="label">PROJECT COORDINATOR/S</p>
          <ArticlesGrid class="single-column">
            <MemberCardMin v-for="(m, ind) in articleData.field_pilot_project_coordinator" class="leader" :key="'coord'+ind"
              :memberData="m" :routesData="routesData" />
          </ArticlesGrid>
        </div>
      </aside>
      

    </div>

    <div class="updates-news dark-bg fullwidth" v-if="newsData && newsData.length">
      <div class="container updates">
        <h2>Project Updates</h2>
        <br>
        <BlockCarousel dotted>
          <ArticleCard v-for="(n, index) in this.newsData" :key="'update'+index" :articleData="n"
            :routesData="routesData" :cat="categoryData(n)" />
        </BlockCarousel>
      </div>
    </div>

    <div class="gray-bg fullwidth" v-if="outputData && outputData.length">
      <div class="container outputs">
        <h2>Output</h2>
        <br>
        <BlockCarousel singleslide dotted>
          <div v-for="(out, ind) in this.outputData" :key="'output'+ind">
            <OutputCard :articledata="out" />
          </div>
        </BlockCarousel>
      </div>
    </div>

    <div class="container pj-members"
      v-if="articleData && membersNum">
      <br>
      <h3>Project Members</h3>

      <ArticlesGrid>
        <!-- change in filteredMembers if active filters -->
        <MemberCardMin v-for="(m, ind) in articleData.field_ref_member" :key="'member '+ind" :memberData="m"
          :routesData="routesData" />
      </ArticlesGrid>

    </div>

    <div class="fullwidth"
      v-if="articleData && articleData.field_pilot_project_partner && articleData.field_pilot_project_partner.length">
      <div class="container">
        <br>
        <h3 v-if="articleData.field_pilot_project_partner">Project Partners</h3>
        <div class="partners-row">
          <keep-alive v-for="(p, ind) in this.articleData.field_pilot_project_partner" :key="'partner'+ind">
            <a v-if="p.attributes.field_website" :href="p.attributes.field_website.uri" target="_blank">
              <img :src="partnerImg(p)" alt="">
            </a>
            <a v-else>
              <img :src="partnerImg(p)" alt="">
            </a>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlockCarousel from '../../components/layout-blocks/BlockCarousel.vue'
  import ArticleCard from '../../components/layout-blocks/ArticleCard.vue'
  import ArticlesGrid from '../../components/layout-blocks/ArticlesGrid.vue'
  import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
  import MemberCardMin from '../../components/layout-blocks/MemberCardMin.vue'
  import ImageItem from '../../components/atomic-elements/ImageItem.vue'
  import OutputCard from '../../components/layout-blocks/OutputCard.vue'
  //import FiltersBlock from '../../components/layout-blocks/FiltersBlock.vue'
  import {
    fetchNodes
  } from '../../libs/drupalClient'
  export default {
    name: 'project',
    components: {
      HeaderIntroImage,
      OutputCard,
      //FiltersBlock,
      MemberCardMin,
      ArticlesGrid,
      ImageItem,
      BlockCarousel,
      ArticleCard
    },
    props: {},
    data: () => {
      return {
        pageData: {
          attributes: {
            title: "",
            subtitle: "",
            tag: ""
          }
        },
        phaseData: [
          'Project call',
          'Inspiration',
          'Synergy',
          'Implementation',
          'Evaluation'
        ],
        articleData: null,
        partnerData: [],
        newsData: [],
        outputData: []
      }
    },
    computed: {
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      routesData() {
        return this.$store.state.routesData
      },
      objecttitle() {
        return this.$route.params.title
      },
      objectnid() {
        var link = this.routesData.filter(r => r.url_check == this.objecttitle)

        if (this.routesData.length <= 0) {
          return ''
        } else {
          if (link.length > 0)
            return link[0].nid
          else
            return '/not-found'
        }
      },
      endpoint() {
        return process.env.VUE_APP_ENDPOINT
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      contactPersonsLength() {
        if(this.loggedUser)
          return this.articleData.field_pilot_project_coordinator.length
        else
          return this.articleData.field_pilot_project_coordinator.filter(m => 
            (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
      },
      membersNum() {
        if(this.loggedUser)
          return this.articleData.field_ref_member.length
        else
          return this.articleData.field_ref_member.filter(m => 
            (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
      }
    },
    methods: {
      orderMembers(array) {
        let first = array.filter(m => m.attributes.field_coordinator_type == '1')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let second = array.filter(m => m.attributes.field_coordinator_type == '2')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let third = array.filter(m => m.attributes.field_coordinator_type == null)
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        return first.concat(second).concat(third)
      },
      partnerImg(p) {
        if (p.field_logo)
          return process.env.VUE_APP_ENDPOINT + p.field_logo.attributes.uri.url
        else
          return ''
      },
      /* searchAfterTyping() {
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
          this.searchFilter = this.searchField
        }, 300);
      }, */
      /* reset() {
        this.countryFilter = 'all'
        this.searchFilter = ''
      }, */
      categoryData(n) {
        var cd = {
          nid: 0,
          name: ""
        }

        if (n.field_ref_working_group)
          switch (n.field_ref_working_group.attributes.drupal_internal__nid) {
            case 14:
              cd.nid = 14
              cd.name = 'School education and Public outreach'
              break;
            case 15:
              cd.nid = 15
              cd.name = 'Educational initiatives in higher education'
              break;
            case 16:
              cd.nid = 16
              cd.name = 'Lifelong learning and workforce training'
              break;
            case 17:
              cd.nid = 17
              cd.name = "Educational research in Quantum Technology"
              break;
            case 18:
              cd.nid = 18
              cd.name = 'Equity and inclusion for QT educational initiatives'
              break;
            default:
              cd.nid = 0
              break;
          }

        return cd
      },
      async findCT() {
        if (this.objectnid != '' && this.objectnid != '/not-found') {
          await fetchNodes('pilot_project', {
              include: [
                'field_logo', 'field_report', 'field_attachments', 'field_ref_working_group',
                'field_pilot_project_coordinator', 'field_ref_member', 'field_pilot_project_partner'
              ],
              nested_include: [
                'field_pilot_project_coordinator.field_organization',
                'field_pilot_project_coordinator.field_photo',
                'field_ref_member.field_organization', 'field_ref_member.field_photo',
                'field_pilot_project_partner.field_logo'
              ],
              filters: [{
                key: 'drupal_internal__nid',
                value: this.objectnid
              }]
            })
            .then(res => {
              this.articleData = res[0]
              
              // this.articleData.field_ref_member = this.orderMembers(this.articleData.field_ref_member
              //   .filter(m => m != null && m.attributes.field_member_data_visibility != 'None'))
                
              this.articleData.field_ref_member.sort((a, b) => {
                return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
              })

              this.articleData.field_pilot_project_coordinator = this.orderMembers(this.articleData
                .field_pilot_project_coordinator
                .filter(m => m != null && m.attributes.field_member_data_visibility != 'None'))

              this.articleData.field_pilot_project_partner = this.articleData.field_pilot_project_partner
                .filter(p => p.field_logo)
              //console.log('project data', this.articleData)
            })

          await fetchNodes('news', {
              include: ['field_ref_project', 'field_ref_working_group']
            })
            .then(res => {
              this.newsData = res.filter(r => {
                if (r.field_ref_project)
                  return r.field_ref_project.attributes.drupal_internal__nid == this.articleData.attributes
                    .drupal_internal__nid
                else return false
              }).sort((a, b) => {
                return a.attributes.field_date > b.attributes.field_date ? 1 : -1
              })
              //console.log(this.newsData)
            })

          await fetchNodes('pilot_project_output', {
            include: ['field_ref_project', 'field_image', 'field_attachments']
          }).then(res => {
            this.outputData = res.filter(op => op.field_ref_project.attributes.drupal_internal__nid == this.articleData.attributes.drupal_internal__nid)
           //console.log(this.outputData)
          })

        } else if (this.objectnid == '/not-found')
          this.$router.push('/not-found')
      }
    },
    async mounted() {
      if (this.objectnid != '')
        await this.findCT()
    },
    watch: {
      async objectnid() {
        await this.findCT()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/variables.scss';


  .pj-members {
    ::v-deep {
      .member.leader {
        .article--thumbnail::before {
          display: none;
        }
      }
    }
  }

  .links:first-child {
    align-items: flex-start !important;
  }

  .updates-news {
    background-color: #151d50;
  }

  .partners-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;

    a {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      display: flex;
      transition: opacity 300ms ease;

      &:hover {
        opacity: .6;
        transition: opacity 300ms ease;
      }

      img {
        width: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }
  }

  .phases {
    display: grid;
    gap: 3em;
    margin: 1.5rem 0 5rem 0;

    .phase {
      position: relative;

      .number {
        position: absolute;
        background: $gradient;
        color: #fff;
        width: 2rem;
        height: 2rem;
        top: -.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .phase-name {
        font-size: 14px;
        position: absolute;
        left: 3rem;
        color: $primaryColor;
        font-weight: 500;
      }

      &.big {
        display: block;
        margin: 0.5rem 0rem;

        .number {
          width: 3rem;
          height: 3rem;
          top: -1rem;
          left: -.5rem;
          font-size: 1.5em;
        }

        .phase-name {
          left: 3rem;
          font-size: 1em;
          color: $secondaryColor;
          font-weight: 600;
        }
      }

      &.grayed {
        .number {
          background: #999;
          color: $primaryColor;
        }

        .phase-name {
          color: #999;
        }
      }
    }
  }

  .pj-members {
    display: flex;
    flex-direction: column;
  }

  .logo {
    max-width: 287px;
    display: block;
    margin: 2rem auto;
    height: auto;
  }

  @media (min-width: 400px) {
    .partners-row {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 768px) {
    .partners-row {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1024px) {

    .page-layout {
      .step .detail--tag {
        margin-bottom: 0;
      }

      .step h1 {
        margin-top: 0;
      }
    }

    .partners-row {
      grid-template-columns: repeat(6, 1fr);
      gap: 2rem;
    }

    .phases {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      background-color: #DFE7F5;
      padding: 3rem 0rem 5rem;
      width: 100%;
      height: 100px;
      gap: 0;
      grid-template-columns: repeat(5, 1fr);

      .phase.big {
        margin: 0rem 0rem;
        display: flex;
        justify-content: center;

        .phase-name {
          left: auto;
          left: 50%;
          transform: translateX(-50%);
        }

        .number {
          left: auto;
        }
      }

      .phase {
        display: flex;
        justify-content: center;

        &:first-child {
          &:after {
            width: 50%;
            right: 0;
          }
        }

        &:last-child {
          &:after {
            width: 50%;
            left: 0;
          }
        }

        &:after {
          content: '';
          height: .5em;
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          border-bottom: 2px #999 dashed;
        }

        .number {
          z-index: 3;
        }

        .phase-name {
          top: 2rem;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
</style>